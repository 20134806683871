const HOURS_TO_SECONDS = 3600
const GRAMS_TO_TONNES = 0.000001
//const KILOGRAMS_TO_GRAMS = 1000
/*const TONNES_TO_SHORT_TONS = 1.102311
const TONNES_TO_LONG_TONS = 0.984207*/
const TONNES_TO_KILOGRAMS = 1000
const TONNES_TO_POUNDS = 2204.622622
const KILOGRAMS_TO_POUNDS = 2.204623
const HECTARES_TO_ACRES = 2.471054
const SQ_METRES_TO_HECTARES = 0.0001
const METRES_TO_KILOMETRES = 0.001
const METRES_TO_MILES = 0.00062137119
// const METERS_TO_FEET = 3.28084
// const US_TO_IMP_BUSHEL = 0.968939
const US_BUSHEL_TO_HECTOLITRE = 0.3523907016688
const IMP_BUSHEL_TO_HECTOLITRE = 0.36368722255958
//const HECTOLITRE_TO_LITRE = 100;
/*const CUBIC_METERS_TO_US_BUSHEL = 28.3776
const CUBIC_METERS_TO_IMP_BUSHEL = 27.49615
const CUBIC_METERS_TO_CUBIC_YARD = 1.30795
const CUBIC_METERS_TO_HECTOLITRE = 10*/
const GRAMS_TO_KILOGRAMS = 0.001

const GRAMS_TO_POUNDS = 0.00220462
//const GRAMS_TO_BUSHELS = 0.000033302
const SQM_TO_HECTARES = 0.0001
const SQM_TO_ACRES = 0.000247105

const GRAM_PER_SQM_TO_LBS_PER_ACRE = 8.92179
const GRAM_PER_SQM_TO_TONNES_PER_ACRE = 0.00404686
const GRAM_PER_SQM_TO_TONNES_PER_HECTARE = 0.01
//const GRAM_PER_SQM_TO_BUSHELS_PER_ACRE = 0.15
const GRAM_PER_SQM_TO_KG_PER_HECTARE = 10

export const TONNES_AC = 'tonnes_ac'
export const POUNDS_AC = 'lbs_acre'
export const TONNES_HA = 'tonnes_ha'
export const BUSHEL_US_AC = 'bu_us_ac'
export const BUSHEL_AC = 'bu_ac'
export const KG_HA = 'kg_ha'
export const G_MSQ = 'g_msq'
export const LB_USBU = 'lb_usbu'
export const LB_ABU = 'lb_abu'
export const KG_HL = 'kg_hl'
export const G_05L = 'g_05l'
export const BU_TONNE = 'bu_tonne'
export const METRES_TO_INCHES = 39.37
export const METERS_TO_MM = 1000

export function convertYield(data, fromUnit, toUnit, testWeight = 1.0) {
  if (fromUnit === toUnit) {
    return data
  }
  let value = data
  //convert all to tonnes/ha
  switch (fromUnit) {
    case TONNES_HA:
      break
    case BUSHEL_US_AC:
      value =
        (value * HECTARES_TO_ACRES * US_BUSHEL_TO_HECTOLITRE * testWeight) /
        TONNES_TO_KILOGRAMS
      break
    case BUSHEL_AC:
      value =
        (value * HECTARES_TO_ACRES * IMP_BUSHEL_TO_HECTOLITRE * testWeight) /
        TONNES_TO_KILOGRAMS
      break
    case TONNES_AC:
      value = value * HECTARES_TO_ACRES
      break
    case POUNDS_AC:
      value = (value * HECTARES_TO_ACRES) / TONNES_TO_POUNDS
      break
    case KG_HA:
      value = value / TONNES_TO_KILOGRAMS
      break
    case G_MSQ:
      value = value * (GRAMS_TO_TONNES / SQ_METRES_TO_HECTARES)
      break
  }
  //If we do not have anything for keys, we will need to convert
  switch (toUnit) {
    case TONNES_HA:
      break
    case BUSHEL_US_AC:
      value =
        (value * TONNES_TO_KILOGRAMS) /
        (HECTARES_TO_ACRES * US_BUSHEL_TO_HECTOLITRE * testWeight)
      break
    case BUSHEL_AC:
      value =
        (value * TONNES_TO_KILOGRAMS) /
        (HECTARES_TO_ACRES * IMP_BUSHEL_TO_HECTOLITRE * testWeight)
      break
    case TONNES_AC:
      value = value / HECTARES_TO_ACRES
      break
    case POUNDS_AC:
      value = value * (TONNES_TO_POUNDS / HECTARES_TO_ACRES)
      break
    case KG_HA:
      value = value * TONNES_TO_KILOGRAMS
      break
    case G_MSQ:
      value = value / (GRAMS_TO_TONNES / SQ_METRES_TO_HECTARES)
      break
  }

  return value
}

export function convertTestweight(data, fromUnit, toUnit) {
  let value = typeof data === 'string' ? parseFloat(data) : data
  if (isNaN(data)) {
    return 0
  }
  if (fromUnit === toUnit) {
    return value
  }
  try {
    //convert all to tonnes/ha
    switch (fromUnit) {
      case KG_HL:
        break
      case LB_USBU:
        value = value / (KILOGRAMS_TO_POUNDS * US_BUSHEL_TO_HECTOLITRE)
        break
      case LB_ABU:
        value = value / (KILOGRAMS_TO_POUNDS * IMP_BUSHEL_TO_HECTOLITRE)
        break
      case BU_TONNE:
        value =
          value == 0
            ? 0
            : 1 / ((value * US_BUSHEL_TO_HECTOLITRE) / TONNES_TO_KILOGRAMS)
        break
    }

    //If we do not have anything for keys, we will need to convert
    switch (toUnit) {
      case KG_HL:
        break
      case LB_USBU:
        value = value * KILOGRAMS_TO_POUNDS * US_BUSHEL_TO_HECTOLITRE
        break
      case LB_ABU:
        value = value * KILOGRAMS_TO_POUNDS * IMP_BUSHEL_TO_HECTOLITRE
        break
      case BU_TONNE:
        value =
          value == 0
            ? 0
            : 1 / ((value * US_BUSHEL_TO_HECTOLITRE) / TONNES_TO_KILOGRAMS)
        break
    }
  } catch (e) {
    console.log(`failed to convert ${data} from ${fromUnit} to ${toUnit}`)
  }
  return value
}

export function convertSpeed(speed, to, from = 'kmh') {
  if (from === to) {
    return speed
  }
  let speedKmH
  switch (from) {
    case 'ms':
      speedKmH = speed / METRES_TO_KILOMETRES / HOURS_TO_SECONDS
      break
    case 'mph':
      speedKmH = (speed / METRES_TO_KILOMETRES) * METRES_TO_MILES
      break
    case 'kmh':
      speedKmH = speed
  }
  switch (to) {
    case 'ms':
      return speedKmH * METRES_TO_KILOMETRES * HOURS_TO_SECONDS
    case 'mph':
      return (speedKmH / METRES_TO_KILOMETRES) * METRES_TO_MILES
    case 'kmh':
      return speedKmH
  }
}

export function convertYieldToPref(data, prefsUnit, buPerTonne) {
  if (buPerTonne == undefined) {
    //average of all crops might not be the greatest default but if we don't have a conversion it's better than nothing.
    buPerTonne = 42.39
  }
  let value = data
  switch (prefsUnit) {
    case 'tonnes_ac':
      value = data * GRAM_PER_SQM_TO_TONNES_PER_ACRE
      break
    case 'lbs_acre':
      value = data * GRAM_PER_SQM_TO_LBS_PER_ACRE
      break
    case 'tonnes_ha':
      value = data * GRAM_PER_SQM_TO_TONNES_PER_HECTARE
      break
    case 'bu_ac':
      value = data * GRAM_PER_SQM_TO_TONNES_PER_ACRE * buPerTonne
      break
    case 'kg_ha':
      value = data * GRAM_PER_SQM_TO_KG_PER_HECTARE
      break
    case 'tonne':
      value = data * GRAMS_TO_TONNES
      break
    case 'bu':
      value = data * GRAMS_TO_TONNES * buPerTonne
      break
    case 'lb':
      value = data * GRAMS_TO_POUNDS
      break
    case 'kg':
      value = data * GRAMS_TO_KILOGRAMS
      break
  }

  return value
}

export function convertAreaToPref(data, prefsUnit) {
  let value = data
  switch (prefsUnit) {
    case 'ha':
      value = data * SQM_TO_HECTARES
      break
    case 'ac':
      value = data * SQM_TO_ACRES
      break
  }
  return value
}

export function convertToFeetInches(meters) {
  let realFeet = (meters * METRES_TO_INCHES) / 12
  let feet = Math.floor(realFeet)
  let inches = Math.round((realFeet - feet) * 12)
  if (inches === 12) {
    feet++
    inches = 0
  }
  return {
    inches: inches,
    feet: feet,
  }
}

export function convertToGrams(data, prefsUnit, buPerTonne) {
  //If we do not have anything for keys, we will need to convert
  let value = data
  switch (prefsUnit) {
    case 'tonne':
      value = data / GRAMS_TO_TONNES
      break
    case 'bu':
      value = data / buPerTonne / GRAMS_TO_TONNES
      break
    case 'lb':
      value = data / GRAMS_TO_POUNDS
      break
    case 'kg':
      value = data / GRAMS_TO_KILOGRAMS
      break
  }

  return value
}
