import { styled } from '@mui/system'
import { FloatingPanel } from 'components/floating'
import {
  // FarmtrxIconDocumentation,
  Info,
} from '@troo/farmtrx-icons'
import { DoubleArrow as DoubleArrowIcon } from '@mui/icons-material'
import { Button, ButtonGroup, buttonGroupClasses, Tooltip } from '@mui/material'
import { useEffect } from 'react'

function getDisplay(selectedState) {
  return selectedState ? 'block' : 'none'
}

function getMarginRight(selectedState) {
  return selectedState ? '8px' : 0
}

function getMaxWidth(selectedState) {
  return selectedState ? 'fit-content' : 'initial'
}

export default function HarvestToolbar(props) {
  const {
    onPointInfo,
    //onAreaInfo,
    //onAddNote
  } = props

  const { t } = useTranslation()
  const [defaultExpandedState, setDefaultExpandedState] = useState(false)
  const [selectedBtn, setSelectedBtn] = useState('')

  useEffect(() => {
    if (selectedBtn === 'point-inspection') {
      onPointInfo()
    }
  }, [selectedBtn])

  const expandToggle = useCallback(() => {
    setDefaultExpandedState(!defaultExpandedState)
  })

  const toggleSelectedButton = useCallback((e, id) => {
    if (selectedBtn === id) {
      setSelectedBtn('')
      onPointInfo()
    } else {
      setSelectedBtn(id)
    }

    setDefaultExpandedState(!defaultExpandedState)
  })

  return (
    <FloatingPanel $position="top">
      <ButtonGroup
        id="harvest-toolbar"
        orientation="vertical"
        aria-label="vertical contained button group"
        variant="contained"
        sx={{
          [`.${buttonGroupClasses.grouped}:hover`]: {
            background: '#F2F2F2',
          },
          [`.${buttonGroupClasses.disabled}:hover`]: {
            background: '#F2F2F2',
          },
          [`.${buttonGroupClasses.disabled}:last-child`]: {
            borderTop: '1px solid gray !important',
          },
        }}
      >
        <Tooltip
          title={t('point_inspection')}
          placement="right"
          disableInteractive
        >
          <ExpandableButton
            id="point-inspection"
            display={getDisplay(defaultExpandedState)}
            marginRight={getMarginRight(defaultExpandedState)}
            maxWidth={getMaxWidth(defaultExpandedState)}
            selected={selectedBtn === 'point-inspection'}
            key="point-inspection"
            startIcon={<Info />}
            onClick={(e) => {
              toggleSelectedButton(e, 'point-inspection')
            }}
          >
            <span>{t('point_inspection')}</span>
          </ExpandableButton>
        </Tooltip>
        {/*<Tooltip title={t('area_inspection')} placement="right" disableInteractive>
          <ExpandableButton
            id="area-inspection"
            display={getDisplay(defaultState)}
            $marginRight={getMarginRight(defaultState)}
            $maxWidth={getMaxWidth(defaultState)}
            key="area-inspection"
            startIcon={
              <CircleOutlinedIcon
                onClick={(e) => showBtnText(e, 'area-inspection')}
              />
            }
            onClick={onAreaInfo}
          >
            <span>{t('area_inspection')}</span>
          </ExpandableButton>
        </Tooltip>
        <Tooltip title={t('add_note')} placement="right">
                    <Button id='add-note' className={`toolButtons ${defaultState ? classes.defaultMode : classes.expandedMode}`} key="add-note" startIcon={<FarmtrxIconDocumentation onClick={(e) => showBtnText(e, 'add-note')} />} onClick={onAddNote}>
                        <span> {t('add_note')} </span>
                    </Button>
        </Tooltip>*/}

        <ExpandableButton
          display={getDisplay(defaultExpandedState)}
          marginRight={getMarginRight(defaultExpandedState)}
          maxWidth={'initial'}
          key="expand"
          startIcon={
            defaultExpandedState ? (
              <DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <DoubleArrowIcon />
            )
          }
          onClick={expandToggle}
        >
          <span>{t('collapse')}</span>
        </ExpandableButton>
      </ButtonGroup>
    </FloatingPanel>
  )
}

const ExpandableButton = styled(Button, {
  shouldForwardProp: (props) =>
    props !== 'display' && props !== 'marginRight' && props !== 'maxWidth',
})(({ display, marginRight, maxWidth, selected }) => ({
  justifyContent: 'start',
  background: selected ? '#3475E0' : '#F2F2F2',
  color: selected ? '#fff' : '#003057',
  maxWidth: maxWidth,
  '& > span:nth-of-type(2)': {
    display: display,
  },
  '& > span:nth-of-type(1)': {
    marginRight: marginRight,
  },
}))
