import { updateTotalYield } from 'api/harvest'
import { FormControlLabel, TextField } from '@mui/material'
import { closeModal } from 'actions/app'
import { useCallback } from 'react'
import { convertYieldToPref } from 'common/unitConvert'
import ModalTool from 'components/modal/modal-tool'

export default function ChangeTotalYield() {
  const userPrefs = useSelector((state) => state.account.singleton.prefs)
  const prefsUnit = userPrefs?.units?.totalyield_in
  const preferenceOptions = useSelector(
    (state) => state.account.options.units.totalYield,
  )
  const [totalYield, setTotalYield] = useState(0)
  const [calcYield, setCalcYield] = useState(0)
  const [unit, setUnit] = useState(prefsUnit)

  const selectedHarvest = useSelector((state) => state.harvest.current)

  useEffect(() => {
    let harvestYield = selectedHarvest?.stats?.total_yield_g
    if (harvestYield) {
      harvestYield = convertYieldToPref(
        harvestYield,
        prefsUnit,
        selectedHarvest?.crop?.buPerTonne,
      )
      setTotalYield(harvestYield.toFixed(2))
      setCalcYield(harvestYield.toFixed(2))
    } else {
      setTotalYield('')
      setCalcYield(0)
    }
  }, [selectedHarvest, prefsUnit])

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  })

  const onChange = useCallback(
    (e) => {
      let _yield = parseFloat(e.target.value)
      if (isNaN(_yield)) {
        _yield = ''
      }
      setTotalYield(_yield)
    },
    [totalYield],
  )

  const onChangeUnit = useCallback((e) => {
    setUnit(e.target.value)
  })

  const onAChangeY = useCallback(() => {
    dispatch(updateTotalYield(selectedHarvest.id, totalYield, unit))
  }, [selectedHarvest, totalYield, unit])

  return (
    <ModalTool
      header={t('change_ty')}
      content={
        <>
          <FormControlLabel
            control={
              <TextField
                id="calculatedTotalYield"
                sx={{ width: '25ch', padding: '10px' }}
                value={calcYield}
                name="calculatedTotalYield"
                variant="outlined"
                size="small"
                disabled
              />
            }
            label={t('calculated_ty')}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <TextField
                id="newTotalYield"
                sx={{ width: '25ch', padding: '10px' }}
                value={totalYield}
                name="newTotalYield"
                variant="outlined"
                size="small"
                type="number"
                error={totalYield === '' || totalYield <= 0}
                helperText={totalYield === '' ? t('required') : ''}
                onChange={onChange}
              />
            }
            label={t('new_total_yield')}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <TextField
                sx={{ width: '25ch', padding: '10px' }}
                select
                name={'item'}
                value={unit}
                onChange={onChangeUnit}
                slotProps={{ select: { native: true } }}
                variant="outlined"
                size="small"
              >
                {Object.keys(preferenceOptions).map((innerItem) => (
                  <option value={innerItem} key={innerItem}>
                    {t(innerItem)}
                  </option>
                ))}
              </TextField>
            }
            label={t('units')}
            labelPlacement="start"
          />
        </>
      }
      onClose={closePopup}
      onApply={onAChangeY}
      disabled={totalYield === '' || totalYield <= 0}
    />
  )
}
