import { styled } from '@mui/system'
import { defaultShadow } from 'components/foundation'
import { V, VItem } from 'components/layout'
import LayersGroup from 'components/layers-group'
import { get, find, isEmpty } from 'lodash-es'
import { getHarvestStatusIcon, EMPTY_ARRAY } from 'common/c'
import { vectorLayers, rasterLayers } from 'common/layer-data'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getTranslatedName } from 'common/misc'
import { Box, MenuItem, Select } from '@mui/material'

export default function SplitPanel(props) {
  const { t } = useTranslation()

  const {
    harvests,
    harvestKey,
    layersKey,
    layerInfoKey,
    harvestYear,
    setYear,
    fieldsHarvestedKey,
    setField,
    findHarvestsByFieldYearCrop,
    filterFieldsCFF,
    setLayers,
    getLayerInfoByHarvest,
    showLayer,
    removeLayer,
    hideLayerLegend,
    harvestFetched,
    fetchFeatures,
    removeLayerLegends,
    resetLayers,
  } = props

  const dispatch = useDispatch()

  const harvest = useSelector((state) => get(state, harvestKey))
  const year = useSelector((state) => get(state, `field.${harvestYear}`))
  const fieldsHarvested = useSelector((state) =>
    get(state, `field.${fieldsHarvestedKey}`),
  )
  const harvestsAll = useSelector((state) => get(state, harvests))

  const [selectedField, setSelectedField] = useState()

  const harvestYears = useSelector((state) => state.harvest.years)

  const layers = useSelector((state) => get(state, `field.${layersKey}`))

  const layerInfo = useSelector((state) =>
    get(state, `field.${layerInfoKey}`, EMPTY_ARRAY),
  )

  const selectedFieldDB = useSelector((state) => get(state, 'field.singleton'))

  const [yearChanged, setYearChanged] = useState(false)

  const subscriptions = useSelector((state) => state.account.subscriptions)

  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedHarvest, setSelectedHarvest] = useState('')
  const [selectedFarms, setSelectedFarms] = useState([])
  const [harvestLayers, setHarvestLayers] = useState([])
  const [harvestOptions, setHarvestOptions] = useState({})

  useEffect(() => {
    const clientId = localStorage.getItem('selectedClient')?.id ?? null
    setSelectedClient(clientId)

    const farms = JSON.parse(localStorage.getItem('selectedFarms'))
    const farmIds = farms?.length ? farms.map((f) => f?.id ?? null) : []
    setSelectedFarms(farmIds)
  }, [localStorage])

  const onChangeHarvest = useCallback(
    (id) => {
      dispatch(harvestFetched(find(harvestsAll, (h) => h.id == id)))
    },
    [harvestsAll],
  )

  useEffect(() => {
    /* On page load in the map split screen, the default displayed field will be the same field that's selected in the Dashboard page */
    onFieldChange(selectedFieldDB.id)
  }, [])

  useEffect(() => {
    if (!isEmpty(harvest)) {
      dispatch(getLayerInfoByHarvest(harvest?.id, year))
    }
  }, [harvest, year])

  const onSeasonChange = useCallback((year) => {
    dispatch(setYear(parseInt(year)))
    setYearChanged(true)
    filterFieldsCFF(year, 'all', selectedClient, selectedFarms)
    dispatch(removeLayerLegends())
    dispatch(resetLayers())
  })

  useEffect(() => {
    if (fieldsHarvested.length && yearChanged) {
      setSelectedField(fieldsHarvested[0])
      dispatch(setField(fieldsHarvested[0]))
      dispatch(findHarvestsByFieldYearCrop(fieldsHarvested[0].id, year, 'all'))
    }
  }, [year, fieldsHarvested])

  const onFieldChange = useCallback((field) => {
    dispatch(removeLayerLegends())
    dispatch(resetLayers())
    setSelectedField(find(fieldsHarvested, (h) => h.id == field))
    dispatch(setField(find(fieldsHarvested, (h) => h.id == field)))
    dispatch(fetchFeatures(field))
    dispatch(findHarvestsByFieldYearCrop(field, year, 'all'))
  })

  const baseVectorLayers = useMemo(() => {
    const _baseVectorLayers = {}
    for (let i = 0; i < vectorLayers.length; i++) {
      _baseVectorLayers[vectorLayers[i].name] = vectorLayers[i]
    }
    return _baseVectorLayers
  }, [vectorLayers])
  const baseRasterLayers = useMemo(() => {
    const _baseRasterLayers = {}
    for (let i = 0; i < rasterLayers.length; i++) {
      _baseRasterLayers[rasterLayers[i].name] = rasterLayers[i]
    }
    return _baseRasterLayers
  }, [rasterLayers])

  useEffect(() => {
    if (harvestsAll.length > 0) {
      const firstHarvest = harvestsAll[0]
      setSelectedHarvest(firstHarvest.id)
      dispatch(harvestFetched(firstHarvest))
    } else {
      setSelectedHarvest('')
    }
    const map = {}

    harvestsAll.map((h) => (map[h.id] = h))
    setHarvestOptions(map)
  }, [harvestsAll])

  useEffect(() => {
    let id = harvest?.id
    const _harvestLayers =
      id && layers[id] != null
        ? layers[id]
        : Object.assign({}, baseRasterLayers, baseVectorLayers)
    if (id && layers[id] == null) {
      let initialLayers = {}
      initialLayers[id] = _harvestLayers
      dispatch(setLayers(initialLayers))
    }
    setHarvestLayers(_harvestLayers)
  }, [layers, harvest])

  const layerInfoMap = useMemo(() => {
    const layerInfoMap = {}
    layerInfo.map((l) => {
      layerInfoMap[l.layerTitle] = l
    })

    return layerInfoMap
  }, [layerInfo])

  const showPremiumMsg = useMemo(() => {
    if (
      harvest &&
      Object.keys(harvest).length &&
      subscriptions &&
      subscriptions.length
    ) {
      let subscriptionsFound = []
      let subscriptionPeriods = subscriptions[0].subscriptionPeriods
      subscriptionsFound = subscriptionPeriods.filter(
        (subscription) =>
          (subscription.subscriptionStatus === 'paid' ||
            subscription.subscriptionStatus === 'free_year' ||
            subscription.subscriptionStatus === 'comped') &&
          ((harvest.startDate >= subscription.startDate &&
            harvest.startDate <= subscription.endDate) ||
            (harvest.endDate <= subscription.endDate &&
              harvest.endDate >= subscription.startDate)),
      )
      if (subscriptionsFound && subscriptionsFound.length) {
        return false
      } else {
        return true
      }
    }
  }, [harvest])

  return (
    <StyledRoot>
      <V>
        <VItem>
          <label>
            {t('year')}
            <Select
              sx={{ fontSize: '0.75em', height: '28px', marginLeft: '3em' }}
              onChange={(e) => {
                onSeasonChange(e.target.value)
              }}
              value={year}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 200 } },
              }}
            >
              {harvestYears && Object.keys(harvestYears) ? (
                harvestYears.map((item) => (
                  <MenuItem value={item.harvestYr} key={item.harvestYr}>
                    {item.harvestYr}
                  </MenuItem>
                ))
              ) : (
                <></>
              )}
            </Select>
          </label>
        </VItem>
        {!isEmpty(fieldsHarvested) ? (
          <>
            <VItem>
              <label>
                {t('field')}
                <Select
                  sx={{
                    fontSize: '0.75em',
                    height: '28px',
                    marginLeft: '3em',
                  }}
                  onChange={(e) => {
                    onFieldChange(e.target.value)
                  }}
                  value={
                    selectedField ? selectedField?.id : selectedFieldDB?.id
                  }
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 200 } },
                  }}
                >
                  {fieldsHarvested && Object.keys(fieldsHarvested) ? (
                    fieldsHarvested.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </label>
            </VItem>
            <VItem>
              <label>
                {t('harvest')}
                <Select
                  sx={{ fontSize: '0.75em', height: '28px', marginLeft: '3em' }}
                  value={selectedHarvest}
                  onChange={(e) => {
                    onChangeHarvest(e.target.value)
                  }}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: 200 } },
                  }}
                >
                  {Object.keys(harvestOptions).map((id) => (
                    <MenuItem key={harvestOptions[id].id} value={id}>
                      <Box display={'flex'} alignItems={'center'}>
                        {harvestOptions[id].cropYear} -{' '}
                        {getTranslatedName(harvestOptions[id].crop.name)}
                        {getHarvestStatusIcon(harvestOptions[id].status)}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </label>
            </VItem>
            <VItem>
              <LayersGroup
                harvestLayers={harvestLayers}
                layerInfoMap={layerInfoMap}
                hideLayerLegend={hideLayerLegend}
                removeLayer={removeLayer}
                showLayer={showLayer}
                harvestId={harvest?.id}
                setLayers={setLayers}
                showVectorLayers={false}
                showPremiumMsg={showPremiumMsg}
              />
            </VItem>
          </>
        ) : (
          <StyledTitle>{t('no_fields')}</StyledTitle>
        )}
      </V>
    </StyledRoot>
  )
}

SplitPanel.propTypes = {
  harvests: PropTypes.string.isRequired,
  harvestKey: PropTypes.string.isRequired,
  layersKey: PropTypes.string.isRequired,
  layerInfoKey: PropTypes.string.isRequired,
  harvestYear: PropTypes.string.isRequired,
  setYear: PropTypes.func.isRequired,
  fieldsHarvestedKey: PropTypes.string.isRequired,
  setField: PropTypes.func.isRequired,
  findHarvestsByField: PropTypes.func.isRequired,
  findHarvestsByFieldYearCrop: PropTypes.func.isRequired,
  setLayers: PropTypes.func.isRequired,
  getLayerInfoByHarvest: PropTypes.func.isRequired,
  filterFieldsCFF: PropTypes.func.isRequired,
  showLayer: PropTypes.func.isRequired,
  removeLayer: PropTypes.func.isRequired,
  hideLayerLegend: PropTypes.func.isRequired,
  harvestFetched: PropTypes.func.isRequired,
  fetchFeatures: PropTypes.func.isRequired,
  removeLayerLegends: PropTypes.func.isRequired,
  resetLayers: PropTypes.func.isRequired,
}

const StyledRoot = styled('div')({
  position: 'absolute',
  left: '10px',
  zIndex: 1,
  top: '10px',
  background: 'white',
  padding: '10px',
  borderRadius: '4px',
  ...defaultShadow,
})

const StyledTitle = styled('span')({})
